import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Form, Image } from 'react-bootstrap';

function Stores(props) {
    return (

        <div className='customers'>
            
            <h1>{props.lang == "ar" ? "أحذية هشام" : "נעלי הישאם"}</h1>
          <Image src="logo.png" style={{width:"20%", height:"auto", borderRadius:"50%"}} className="my-5"/>
          <h1 className='pb-5'>{props.lang == "ar" ? "فروع" : "סניפים"}</h1>
            <h5 className='justify-content-start'>{props.lang == "ar" ? "سديروت ويدجوود 5, حيفا" : "שדרות ודג'ווד 5, חיפה"}</h5>
            <h5>{props.lang == "ar" ? "جراند كنيون حيفا" : "גרנד קניון חיפה"}</h5>
            <h5>{props.lang == "ar" ? "توفيق زياد 53, الناصرة" : "תופיק זיאד 53, נצרת"}</h5>
            
            


        </div>
    );
}

export default Stores;