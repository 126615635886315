import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Col, Form, Image, Row } from 'react-bootstrap';

function Sale(props) {
    return (


        <div className='customers'>
            <h1>{props.lang == "ar" ? "أحذية هشام" : "נעלי הישאם"}</h1>
          <Image src="logo.png" style={{width:"20%", height:"auto", borderRadius:"50%"}} className="my-5"/>
            <h1 style={{color:"yellow"}}>{props.lang == "ar" ? "التنزيلات" : "מבצעי החודש"}</h1>
            <Image src="sale.png" style={{maxWidth:"100%", height: "auto"}}/>
        
        </div>

    );
}

export default Sale;