import { Image } from "react-bootstrap";

function Home(props) {
  return (

    <div className='content' >
          
          
          <h1>{props.lang == "ar" ? "أحذية هشام" : "נעלי הישאם"}</h1>
          <h3>{props.lang == "ar" ? "عنوان كل الناس" : "כשאיכות ונוחות נפגשים"}</h3>
          <Image src="logo.png" style={{width:"20%", height:"auto", borderRadius:"50%"}} className="mt-5"/>
          
      </div>
  );
}

export default Home;